<template>
  <div>
    <div class="login-all">
      <div class="login-bg"></div>
      <div class="login-body">
        <div class="body-left"></div>
        <div class="body-right">
          <!-- 信息完善 -->
          <div class="checkCode">
            <div class="UP-top">
              <h1>通行证 信息完善</h1>
            </div>
            <div class="UP-center">
              <!-- <div :class="['Information',{'moveHover': RignickName == false}]"> 
                <el-input v-model="usernickName" placeholder="请输入昵称" ></el-input>
                <div v-if="Rignickname" class="errortxt">请输入昵称</div>
              </div>
              <div :class="['Information',{'moveHover': RigEmail == false},    {'borderchange': RigEmail}]">
                  <el-input v-model="userEmail" placeholder="请输入邮箱" @blur="checkPhone()"></el-input>
                  <div v-if="RigEmail" class="errortxt">请输入邮箱</div>
              </div>
                
              <div :class="['Information',{'moveHover': RigPwd == false}, {'borderchange': RigPwd}]">
                  <el-input v-model="userpwd" placeholder="请输入密码" @blur="checkPwd()"></el-input>
                  <div v-if="RigPwd" class="errortxt">请输入正确密码</div>
              </div>
              <div :class="['Information',{'moveHover': RigPwd == false}, {'borderchange': RigPwd}]">
                  <el-input v-model="userpwds" placeholder="请确认密码" @blur="checkPwd()"></el-input>
                  
                  <div v-if="RigPwds" class="errortxt">请输入正确密码</div>
              </div>
              <div>
                  <el-radio-group v-model="radio">
                    <el-radio :label="0">男</el-radio>
                    <el-radio :label="1">女</el-radio>
                  </el-radio-group>
              </div> -->
               <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="75px" class="demo-ruleForm Informations">
                  <el-form-item label="昵称" prop="nickName">
                    <el-input v-model="ruleForm.nickName" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-radio-group v-model="ruleForm.gender">
                    <el-radio :label="0">女</el-radio>
                    <el-radio :label="1">男</el-radio>
                  </el-radio-group>
                </el-form>
            </div>
            <div class="UP-bottom" @click="submitForm('ruleForm')"><div class="login-btn">下一步</div></div>
          </div>
          
        </div>
      </div>
    </div>
 </div>
</template>

<script>
import "./login.css"
import { replenish, ifreplenish } from '@/api/register'
import { setCookie } from "../../api/method"
export default {
  name: '',
  data () {
    return {
      usernickName: '',//昵称
      userEmail: '',//邮箱
      userpwd: '',//密码
      userpwds: '',//确认密码
      // showHide: require("../../assets/hide.png"),
      RigPwd: false, //密码校验
      RignickName: false, //密码校验
      RigEmail: false, //手机校验
      RigPwds: false, //确认密码校验
      getchangeCode: '发送验证码',//按钮消息
      timer: null,//定时器
      num: '',
      isClick: false,
      radio: 0 ,//姓别
      ruleForm: {
        nickName: '',
        email: '',
        pass: '',
        checkPass: '',
        gender: 0
      },
      rules: {
        nickName: [
          { validator: this.validatenickName, trigger: 'blur' }
        ],
        email: [
          { validator: this.validateEmail, trigger: 'blur' }
        ],
        pass: [
          { validator: this.validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: this.validatePass2, trigger: 'blur' }
        ],
        
      }
    }
  },
  props: {},
  components: {},
  mounted () {
  },
  methods: {
    validatenickName(rule, value, callback) {
       if (value == '') {
          return callback(new Error('昵称不能为空'));
        } else {
          callback()
        }
    },
    validateEmail(rule, value, callback) {
       const reg = new RegExp("^([A-z0-9_\.-]+)@([\dA-z\.-]+)\.([A-z\.]{2,6})$")
        if(!reg.test(value)){
          return callback(new Error('请输入正确邮箱'));
        } else {
          callback()
        }
    },
      validatePass(rule, value, callback){
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      },
      validatePass2(rule, value, callback){
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
      //提交表单
       submitForm(formName) {
        
        this.$refs[formName].validate((valid) => {
          // console.log(this.$route.params.id)
          if (valid) {
            // console.log(this.$route.params.id)
            
            if(this.$route.params.id && this.$route.params.id == 1) {
              console.log('111')
              const Information = {
                mobile: window.localStorage.getItem('ifUser'),
                nickname: this.ruleForm.nickName,
                email: this.ruleForm.email,
                password: this.ruleForm.pass,
                gender: this.ruleForm.gender
              }
              //非扫码
              ifreplenish(Information).then(res => {
                console.log(res)
                if(res.data.code == 0) {
                  // console.log(res.data.data.accessToken)
                  setCookie('accessToken',res.data.data.accessToken,1)
                  // setCookie('id', res.data.data.id,1)
                  // setCookie('phone', res.data.data.phone,1)
                  setCookie('refresh_token', res.data.data.refreshToken,1)//authorization
                  this.$router.push('/passportInfo')
                }
                // this.$router.push('/passportInfo')
                
              })
            }else {
              console.log('2222')
              
              const Information = {
                nickname: this.ruleForm.nickName,
                email: this.ruleForm.email,
                password: this.ruleForm.pass,
                gender: this.ruleForm.gender
              }
              //扫码
              replenish(Information).then(res => {
                console.log(res)
                this.$router.push('/passportInfo')
                
              })
              // console.log(Information)
              
              // console.log(Information,'111')
              }   
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      
  }
}
</script>

<style scoped lang='less'>
</style>
