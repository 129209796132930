/**
 * 数据请求
 */
import request from '@/utils/request.js'
import { getCookie } from './method'



//用户注册
export const registerTo = data => {
  return request({
    method: 'POST',
    url: '/reg',
    /* headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }, */
    dataType:'json',
    data 
  })
}
//获取验证码
export const getsmsCode = data => {
  return request({
    method: 'POST',
    url: '/api/send/smsCode',
    dataType:'json',
    headers:{
      "Content-Type":true
    },
    data 
  })
}
//绑定手机号
export const checksmsCode = data => {
  return request({
    method: 'POST',
    url: '/api/user/bind/mobile',
    dataType:'json',
    headers:{
      "Content-Type":true
    },
    data 
  })
}
//信息完善
export const replenish = data => {
  return request({
    method: 'POST',
    url: '/api/user/replenish',
    dataType:'json',
    headers:{
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type": true
    },
    data 
  })
}
//手机验证码注册的完善信息
export const ifreplenish = data => {
  return request({
    method: 'POST',
    url: '/api/user/register/replenish',
    dataType:'json',
    headers:{
      "Content-Type": true
    },
    data 
  })
}
